<template>
    <button type="button" class="btn btn-default btn-sm" @click="routerBack">
        <i class="fa fa-angle-left"></i> Back
    </button>
</template>


<script>
export default {
    data() {
        return {
            // Code...
        }
    },
    created() {
        // Code...
    },
    methods: {
        routerBack() {
            this.$router.go(-1)
        }
    }
}
</script>


<style scoped>

</style>
